@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Apresentation {
  align-items: flex-start;
  background-color: $green;
  cursor: url("../../images/volume.ico"), auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
  position: relative;
  scroll-snap-align: start;

  video {
    left: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: fill;
    opacity: 0.4;
    position: absolute;
    top: 0;
    z-index: 0;
  }

  div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    p {
      color: $white;
      font-family: $primary_font;
      font-size: 120px;
      line-height: 180px;
      margin: 0;
      text-align: right;

      &:nth-child(2) {
        opacity: 0.82;
      }

      &:nth-child(3) {
        opacity: 0.66;
      }

      &:nth-child(4) {
        opacity: 0.35;
      }

      span {
        font-family: $primary_font_italic;
      }

      @include breakpoint(desktop-small) {
        font-size: 160px;
      }

      @include breakpoint(desktop-medium) {
        font-size: 170px;
      }

      @include breakpoint(desktop-large) {
        font-size: 191px;
      }
    }
  }
}
