@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Button {
  align-items: center;
  background-color: $black;
  border: 1px solid $white;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-family: $primary_font;
  font-size: 18px;
  padding: 10px 20px;
  text-transform: uppercase;

  @include breakpoint(desktop-medium) {
    font-size: 38px;
    padding: 0px 15px;
  }

  &:hover {
    background-color: $white;
    color: $black;
  }

  &.active {
    background-color: $white;
    color: $black;
  }

  &.simple {
    border: none;
    border-bottom: 1px solid transparent;
    padding: 0;

    &.active {
      background-color: $white;
      border-bottom-color: $black;
      color: $black;
      font-family: $primary_font;
    }
    
    &:hover {
      background-color: $white;
      border-bottom-color: $black;
      color: $black;
    }
  }
}
