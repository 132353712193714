@import './fonts';

$white: #ffffff;
$black: #000000;
$green: #009B0C;

$primary_font: "Lekton Regular";
$primary_font_demi: "Lekton Bold";
$primary_font_italic: "Lekton Italic";
$primary_font_roboto_light: "Roboto Condensed Light";

$breakpoints: (
 "mobile-medium": 400px,
 "mobile-larger": 576px,
 "tablet": 768px,
 "tablet-large": 992px,
 "desktop-small": 1024px,
 "desktop-medium": 1440px,
 "desktop-large": 1600px,
 "desktop-extra-large": 1800px,
 "desktop-4k": 2500
);
