@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Filter {
  background-color: $black;
  min-height: 100vh;
  padding-bottom: 200px;
  position: relative;
  scroll-snap-align: start;
  
  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 120px 130px;

    .filter-group-buttons {
      width: max-content;
    }

    .filter-group {
      Button {
        margin-right: 22px;
      }
    }
  }

  section {
    background-color: $black;
    box-sizing: border-box;
    display: grid;
    flex-wrap: wrap;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(auto-fill, 22%);
    justify-content: flex-start;
    padding: 0 0 0 120px;
    width: 100%;
    z-index: 2;

    .no-course {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include breakpoint(desktop-small) {
        padding: 56px 120px 110px;
      }

      h3 {
        font-family: $primary_font;
        margin: 0 0 46px;
        text-align: left;
      }

      p {
        font-family: $primary_font;
        text-align: left;
      }
    }

    .text-home {
      box-sizing: border-box;
      height: 200px;
      width: 200px;

      .container-info {
        position: relative;
      }

      p {
        color: white;
        font-family: $primary_font;
        font-size: 56px;
        margin: 0;
        position: absolute;
        right: -30%;
        transform: matrix(0, -1, 1, 0, 0, 59);

        span {
          color: $green;
          font-family: $primary_font_italic;
        }

        @include breakpoint(desktop-medium) {
          font-size: 96px;
        }
      }
    }
  }

  .six-part {
    padding-left: 120px;
    position: relative;

    .text-marketplace {
      box-sizing: border-box;
      height: 370px;
      width: 285px;

      .container-info {
        align-items: flex-end;
        display: flex;
        height: 45%;
        justify-content: flex-start;
        position: relative;

        @include breakpoint(desktop-medium) {
          height: 65%;
        }
      }

      p {
        color: white;
        font-family: $primary_font;
        font-size: 66px;
        left: -35%;
        margin: 0;
        position: absolute;
        transform: matrix(0, -1, 1, 0, 0, 59);

        span {
          color: $green;
          font-family: $primary_font_italic;
        }

        @include breakpoint(desktop-medium) {
          font-size: 96px;
        }
      }
    }
  }

  .frame-video {
    position: fixed;
    top: 0%;
    background-color: rgba(0, 0, 0, 0.663);

    .container-video {
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .container-button {
        padding-top: 200px;
        height: 100%;
        display: flex;
        justify-content: flex-start;

        button {
          // width: 100px;
          height: 50px;
        }
      }

      video {
        width: 50%;
        height: 80%;
      }
    }

  }
}