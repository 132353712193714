@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Card {
  background-color: black;
  box-sizing: border-box;
  cursor: pointer;
  grid-row-end: span 22;
  height: 385px;
  width: calc(102% - 8px);

  .container-info {
    backdrop-filter: brightness(0.5);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    padding: 0 0 26px 50px;
    transition: backdrop-filter 0.2s;

    p {
      color: white;
      font-family: $primary_font_roboto_light;
      font-size: 18px;
      margin: 0;

      @include breakpoint(desktop-medium) {
        font-size: 23px;
      }
    }

    &:hover {
      backdrop-filter: brightness(0.9);
    }
  }

  &.marketplace {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    grid-row-end: span 44;
    height: 730px;
    justify-content: flex-start;
    position: relative;

    p {
      color: white;
      font-family: $primary_font;
      font-size: 56px;
      line-height: 10px;
      margin: 0;
      position: absolute;
      right: 12%;
      transform: matrix(0, -1, 1, 0, 0, 59);

      span {
        color: $green;
        font-family: $primary_font_italic;
      }

      @include breakpoint(desktop-medium) {
        font-size: 96px;
      }
    }
  }

  &.home {
    display: flex;
    position: relative;

    p {
      color: white;
      font-family: $primary_font;
      font-size: 56px;
      margin: 0;
      position: absolute;
      right: -16%;
      transform: matrix(0, -1, 1, 0, 0, 59);

      span {
        color: $green;
        font-family: $primary_font_italic;
      }

      @include breakpoint(desktop-medium) {
        font-size: 96px;
      }
    }
  }

  &.question {
    display: flex;
    flex-direction: column;
    gap: 30px 0;
    justify-content: flex-start;
    padding: 50px 0 0 50px;

    p {
      color: $white;
      font-family: $primary_font_roboto_light;
      font-size: 25px;
      line-height: 23px;
      margin: 0;

      &:first-child {
        color: $green;
        font-family: $primary_font;
        font-size: 58px;
        height: 30px;

        span {
          font-family: $primary_font_italic;
        }
      }

      @include breakpoint(desktop-medium) {
        font-size: 29px;
        line-height: 29px;
      }
    }

    &:hover {
      p {
        color: $green;

        &:first-child {
          color: $white;
        }
      }
    }
  }
}
