@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixins';

.Home {
  align-items: center;
  background-color: $black;
  display: flex;
  flex-direction: column;
  font-family: $primary_font;
  font-size: 2rem;
  height: 100vh;
  position: relative;
  scroll-snap-align: start;

  .container-home {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 140px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    p {
      color: $white;
      font-size: 25px;
      height: 30px;
      letter-spacing: 0px;
      margin: 0;
    }
  }

  h1 {
    color: $white;
    font-family: $primary_font;
    font-size: 100px;
    font-weight: 100;
    height: min-content;
    line-height: 158px;
    margin: 0px -20px !important;
    padding-left: 0px;

    @include breakpoint(desktop-small) {
      font-size: 200px;
    }

    @include breakpoint(desktop-medium) {
      font-size: 280px;
      line-height: 220px;
    }

    @include breakpoint(desktop-large) {
      font-size: 318px;
      line-height: 250px;
      margin: 0 -25px !important;
    }

    @include breakpoint(desktop-extra-large) {
      margin: 0 -25px !important;
    }

    strong {
      display: contents;
      font-weight: 100;
    }

    span {
      color: $green;
      display: contents;
      font-family: $primary_font_demi;

      &.italic {
        font-style: italic;
        font-family: $primary_font_italic;
      }
    }
  }
}