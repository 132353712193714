@mixin font-face($font-family, $font-weight, $font-style, $font-display, $local, $ttf-suffix) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: $font-display;
    unicode-range: U+000-5FF;
    src: local($local), url($ttf-suffix) format('truetype');
  }
}

@include font-face("Lekton Regular",
  400,
  normal,
  swap,
  "Lekton",
  "../fonts/Lekton-Regular.ttf");

@include font-face("Lekton Italic",
  400,
  normal,
  swap,
  "Lekton",
  "../fonts/Lekton-Italic.ttf");

@include font-face("Lekton Bold",
  700,
  normal,
  swap,
  "Lekton",
  "../fonts/Lekton-Bold.ttf");

@include font-face("Roboto Condensed Light",
  300,
  normal,
  swap,
  "Roboto",
  "../fonts/RobotoCondensed-Light.ttf");
