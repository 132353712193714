.App {
  &.scroll-snap-container {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;

    &::-webkit-scrollbar {
      visibility: hidden;
      display: none;
    }
  }
}
